import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSubscriptionType, setModalType } from '../../actions';
import './Pricing.css';
import _ from 'lodash';
import utils from '../../utils';
import plansLogo from '../../images/plans.png';
import rapidLogo from '../../images/rapid-logo.png';
import rapidLogoSmall from '../../images/rapid-logo-small.png';


export default class Pricing extends Component {

  render() {
    
    return (
      <div className="pricing">
        <div className="pricing-background"></div>
        <div className="pricing-content">
          {!utils.rapidOnly && <div>
            <img className="pricing-header-logo" src={plansLogo} alt="pricing header" />
            <div className="pricing-subheader">Choose your plan</div>
          </div>}
          {utils.rapidOnly && <RapidNotice />}
          <div className="comparison">
            <ComparisonSection type="basic" history={this.props.history} />
            <ComparisonSection type="standard" isLive history={this.props.history} />
            <ComparisonSection type="premium" isLive history={this.props.history} />
          </div>
          {/*<div className="test-key-email">
            Want an API test key?  Send us an <em onClick={() => {window.location = utils.mailTo}}>email</em>.
          </div>*/}
        </div>
      </div>
    )
  }
}

const ComparisonSection = props => {

  return (
    <div className="comparison-type">
      <ComparisonRow text={/*_.startCase(props.type)*/`${utils.subscriptionTypes[props.type].name}`}/>
      <ComparisonRow>
        <span>$</span>
        {`${utils.subscriptionTypes[props.type].price}`}
        <span>/mo</span>
      </ComparisonRow>
      <ComparisonRowIcon text="NFL, NBA, MLB, NHL, NCAAF, NCAAB"/>
      <ComparisonRowIcon text="Full league schedules"/>
      <ComparisonRowIcon text="Real-time scores" />
      <ComparisonRowIcon
        text="Current and opening betting odds"
        isNew={false}
      />
      <ComparisonRowIcon
        text="Full odds movement history" 
        isNew={false}
        altIcon={props.type === "basic"}
      />
      {!utils.noStandings && <ComparisonRowIcon text="Conference & division standings"/>}
      <ComparisonRow text={`${utils.subscriptionTypes[props.type].rpm.toLocaleString()} requests / day`}/>
      <ComparisonRow text={`${utils.subscriptionTypes[props.type].rps.toLocaleString()} requests / second`}/>
      <ComparisonButton 
        amount={utils.subscriptionTypes[props.type].price * 100}
        description="Premium Subscription"
        type={props.type}
      />
    </div>
  )
}


const ComparisonRow = props => (
  <div className="comparison-row">{props.children}{props.text}</div>
)

const ComparisonRowIcon = props => (
  <div className={"comparison-row" + (props.altIcon ? " alt" :"")}>
    <i className={`icon ${props.altIcon ? "times": "check circle outline"}`}></i>
    <span className={props.highlight ? "highlight": ""} >{props.text}</span>
    {props.isNew && <em>NEW</em>}
  </div>
)

const RapidNotice = props => (
  <div className="rapid-notice">
    <p>Our API is available through RapidAPI, which provides straightforward tools for effortless account management.  
      Visit our RapidAPI page to get started.</p>
    <div className="rapid-link-holder">
      <img src={rapidLogo} alt="rapid api logo" onClick={goToRapid} />
    </div>
  </div>
)


const mapStateToProps = state => ({
  user: state.user
})

const mapActionsToProps = {
  setSubscriptionType: setSubscriptionType,
  setModalType: setModalType
}


const ComparisonButton = connect(mapStateToProps, mapActionsToProps)(props => {

  const userSub = props.user.subscriptionType;
  const defaultText = utils.rapidOnly ? "Get Started": "Start Today!"
  const attributes = (() => {
    switch(true) {
      // case utils.signupLocked: return {
      //   buttonText: "Coming Soon",
      //   class: "",
      //   disabled: true
      // };
      case userSub === props.type: return {
        buttonText: "Current Plan",
        class: "gray",
        disabled: true
      };
      case userSub && utils.subscriptionTypes[userSub].index < utils.subscriptionTypes[props.type].index: return {
        buttonText: "UPGRADE PLAN",
        class: "gold"
      };
      case !!userSub: return {
        buttonText: "Change Plan",
        class: ""
      };
      default: return {
        buttonText: defaultText,
        class: ""
      };
    }
  })();
  const onClick = e => {
    if (utils.rapidOnly) {
      goToRapid(e);
    } else {
      props.setSubscriptionType(props.type.toLowerCase());
      props.setModalType(props.user.subscriptionType ? "update_plan": "signup");
    }
    
  }

  return (
  
    <div className="comparison-row">
        {props.user.loginChecked && <button 
          className={`comparison-button ${attributes.class || ""}`}
          disabled={attributes.disabled} 
          onClick={onClick}
        >
          {attributes.buttonText}
          {utils.rapidOnly && <img src={rapidLogoSmall} alt="rapid api logo" />}
        </button>}
    </div>
  )
})

function goToRapid(e) {
  e.preventDefault();
  window.open("https://rapidapi.com/SportspageFeeds/api/sportspage-feeds/pricing", "_blank")
}



