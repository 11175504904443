import React from 'react';
import { connect } from 'react-redux';
import { setModalType } from '../../actions';
import './Footer.css';

const Footer = props => {

  const showFooter = props.location.pathname.indexOf("/documentation") < 0;
  const isPricing = props.location.pathname.indexOf("/pricing") >= 0;
  const linkClass = isPricing ? "pricing-footer": "";

  return (
    showFooter && <div className="footer">
      <div className="footer-inner" >
        <ul className={`footer-link-list ${linkClass}`} >
        <li ><div className="footer-link" onClick={() => {props.history.push("/pricing")}} >Pricing</div></li>
        <li ><div className="footer-link" onClick={() => {props.history.push("/faq")}} >FAQ</div></li>
          <li ><div className="footer-link" onClick={() => props.setModalType("contact")} >Contact</div></li>        
        </ul>
      </div>
    </div>
  )
}


const mapActionsToProps = {
  setModalType: setModalType
} 


export default connect(undefined, mapActionsToProps)(Footer);
