

export default function modalReducer(state = "closed", {type, payload}) {

	switch(type) {
		case 'CLOSE_POPUP': return "closed";
		case 'SHOW_SIGNUP': return "signup";
		case 'SHOW_LOGIN': return "login";
		case 'FORGOT_PASSWORD': return "forgot_password";
		case 'CHANGE_PASSWORD': return "change_password";
		case 'UPDATE_CARD': return "update_card";
		case 'UPDATE_PLAN': return "update_plan";
		case 'CONTACT': return "contact";
		case 'CONFIRM': return "confirm";
		case 'API_KEY_SENT': return "api_key_sent";
		default: return state;
	}
}
