

export default {
    subscriptionTypes: {
        basic: {
            name: "Pro",
            price: 39,
            index: 1,
            // rpm: 3000,
            rpm: 150,
            rps: 50
        },
        standard: {
            name: "Ultra",
            price: 69,
            index: 2,
            // rpm: 100000,
            rpm: 3000,
            rps: 50
        },
        premium: {
            name: "Mega",
            price: 139,
            index: 3,
            // rpm: 1000000,
            rpm: 30000,
            rps: 50
        },
    },
    signupLocked: process.env.REACT_APP_SIGNUP_LOCKED === "true",
    noStandings: process.env.REACT_APP_STANDINGS !== "true",
    rapidOnly: true // process.env.REACT_APP_RAPID_ONLY === "true"
    // mailTo: "mailto:sportspagefeeds@gmail.com"
}
