import React from 'react';
import './Terms.css';

function openStripe(e) {
	e.preventDefault();
	window.open("https://stripe.com/us/privacy", "_blank")
}


export default (props) => {

  	return (
	    <div className="terms-holder">
	    	<div className="terms-header">Terms and Conditions</div>
	    	<TermsHeader title="1. Introduction"/>
	    	<p className="terms-body">
	      		Sportspage Feeds ("Sportspage") offers subscription-based access to a sports schedules and scores API for the following US leagues: 
	      		NFL, NBA, MLB, NHL, NCAA Football (FBS) and NCAA Men's Basketball (Division 1).
	      	</p>
		  	<p className="terms-body">
		  		Additionally, Sportspage provides certain team and league data and, where available, betting odds for supported games.
		  	</p>
		  	<TermsHeader title="2. API Access"/>
		  	<p className="terms-body">
		  		Access to the Sportspage API requires a valid, active API key.  Access will be available immediately upon approval of your first month's 
		  		payment and until cancelation. You may cancel your plan at any time through your Dashboard and your subscription will remain active until the end of the billing period.  
		  		Under most circumstances, refunds will not be given for cancelations occuring in the middle of a billing period.
		  	</p>
		  	<p className="terms-body">
		  		You will be billed each month until you cancel your plan.  If your card is subsequently declined, your access will not renew until 
		  		your card details have been updated in your Dashboard.
		  	</p>
		  	<TermsHeader title="3. Personal Information"/>
		  	<p className="terms-body">
		  		Sportspage utilizes Stripe to process subscription payments.  Other than your email address, no personal data collected 
		  		through the signup process is retained by Sportspage.  All other data related to payment processing (e.g., credit card details) 
		  		are retained by Stripe.  In purchasing a Sportspage subscription, you agree to the Terms outlined here as well as 
		  		to Stripe's data retention policies, which can be found <span className="terms-link" onClick={openStripe}>here</span>.
		  	</p>
		  	<TermsHeader title="4. Limitation of Liability"/>
		  	<p className="terms-body">
		  		We aim to provide low-cost access to sports data for web/app developers.  We make every effort to ensure that our data is 
		  		accurate, up-to-date, and provided without interruption.  Scores are updated on a "near-live" basis (generally, within about one minute) 
		  		and betting odds (i.e., spreads, moneylines, and over-unders) data approximately every 15 minutes.  Betting odds are provided 
		  		for most events occurring mid-February 2019 or later but are not guaranteed for all such events and are provided as available.
		  	</p>
		  	<p className="terms-body">
		  		While we do make these efforts, in purchasing a Sportspage subscription, you agree to hold us harmless for any incomplete, 
		  		inaccurate, or stale data or interruptions or termination of service.  At our discretion, we may provide a refund in the event 
		  		of termination or significant interruption of service.
		  	</p>
		  	<em className="rapid-disclaimer">***RapidAPI users may be subject to different terms regarding API Acess and Personal Information.</em>
	    </div>
  	)
}


const TermsHeader = (props) => <div className="terms-subheader">{props.title}</div>




