import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Scrollchor from 'react-scrollchor';
import { connect } from 'react-redux';
import axios from 'axios';
import fileDownload from 'js-file-download';
import utils from '../../utils';
import './Documentation.css';
import navLogo from '../../images/nav-logo.png';

const scrollDuration = 100;
const apiUrl = "https://api.sportspagefeeds.com/"


function openRapid(e) {
	e.preventDefault();
	window.open("https://rapidapi.com/SportspageFeeds/api/sportspage-feeds", "_blank")
}

export default class Docs extends Component {

  render() {

    return (
    	<div className="docs">
    		<img alt="home logo" src={navLogo} onClick={() => this.props.history.push("/")} />
	      	<DocsSidebar history={this.props.history} />
	      	<DocsBody history={this.props.history} />	
    	</div>
    )
  }
}


const DocsSidebar = props => {
	
	return (
		<div className="docs-sidebar">
			{/*<img alt="home logo" src={navLogo} onClick={() => props.history.push("/")} />*/}
			<div className="docs-sidebar-inner">
				<img alt="home logo" src={navLogo} onClick={() => props.history.push("/")} />
				{/*<div className="docs-sidebar-header">Documentation</div>*/}
				<div className="sidebar-links-holder">
					<div><ScrollLink id="initial" name="Initial Setup"></ScrollLink></div>
					<div><ScrollLink id="json-xml" name="JSON or XML"></ScrollLink></div>
					<div><ScrollLink id="endpoints" name="Endpoints"></ScrollLink></div>
					<ul>
						{/*<li><ScrollLink id="subscription" name="Subscription"></ScrollLink></li>*/}
						<li><ScrollLink id="conferences" name="Conferences"></ScrollLink></li>
						<li><ScrollLink id="teams" name="Teams"></ScrollLink></li>
						<li><ScrollLink id="games" name="Games"></ScrollLink></li>
						<li><ScrollLink id="gameById" name="Game By ID"></ScrollLink></li>
						<li><ScrollLink id="odds" name="Odds"></ScrollLink></li>
						{!utils.noStandings && <li><ScrollLink id="standings" name="Standings"></ScrollLink></li>}
					</ul>
				</div>
			</div>
		</div>
	)
}

const ScrollLink = props => <Scrollchor className="docs-link" to={`#${props.id}`} animate={{offset: 0, duration: scrollDuration}} >{props.name}</Scrollchor>


class _DocsBody extends Component {

	state = {
		showApiKey: false
	}

	download() {
		axios.get('/download').then(response => {
			fileDownload(response.data, 'sportspage.js');
		}).catch(err => alert(""));
	}

	render() {


		return (
			<div className="docs-body">
				<div className="docs-body-inner">
					{/*<h1 className="docs-body-inner-header">Documentation</h1>*/}
					<div className="initial-setup">
						<div id="initial" className="anchor"/>
						<h1 >Initial Setup</h1>
						<p>To access the API, you must obtain an API key via our signup page on <a target="_blank" href="https://rapidapi.com/SportspageFeeds/api/sportspage-feeds/pricing">RapidAPI</a>.  Your key must be 
						added as a request header for each request as follows.</p>
						<div className="docs-api-key">
							<div className="generic-dark-space">
								<span className="header-attribute">{'x-api-key:'}</span>
								<span className="header-value">{this.state.showApiKey ? this.props.user.apiKey: '<YOUR_API_KEY>'}</span>
							</div>
							<button className="docs-show-api-key" disabled={!this.props.user.loggedIn} onClick={() => this.setState({showApiKey: true})}>Show Key</button>
						</div>
						
						{/*<p>For JavaScript projects, the following file can be included in your project to simplify the process of making 
							requests: <span className="download-link" onClick={this.download}>sportspage.js</span>.
						</p>*/}
					</div>
					<div className="initial-setup">
						<div id="json-xml" className="anchor"/>
						<h1 className="endpoints-header">JSON or XML</h1>
						<p>By default, data responses are formatted as JSON.  However, any API request can be returned as XML by
						adding <em>xml=true</em> to the GET query.</p>
						<div className="json-xml-header">
							<div className="api-example-header">Example</div>
						</div>
						<div className="api-example-body xml">
							<div className="api-example-inner-header">JSON:</div>
							<div className="api-example-inner-body">https://sportspage-feeds.p.rapidapi.com/teams?league=NFL</div>
							<div className="api-example-spacer" />
							<div className="api-example-inner-header">XML:</div>
							<div className="api-example-inner-body">https://sportspage-feeds.p.rapidapi.com/teams?league=NFL&xml=true</div>
						</div>
					</div>
					<div id="endpoints" className="anchor"/>
					<h1 className="endpoints-header">Endpoints</h1>
					<p>Below is the list of available endpoints.  All endpoints are accessed via GET requests.  With the exception of<b> </b><em className="endpoint">/games</em> and<b> </b>
					<em className="endpoint">/gameById</em>, all endpoints require the <em>league</em> parameter.  The league codes for supported leagues are as follows:</p>
					<div className="league-list">
						<div>NFL</div>
						<div>NBA</div>
						<div>MLB</div>
						<div>NHL</div>
						<div>NCAAF</div>
						<div>NCAAB</div>
					</div>
					{/*<ParamsSection 
						path="subscription"
						overview="Returns user's subscription details and API usage statistics for the current month."
						arguments={[]}
						exampleBody={`${apiUrl}subscription`}
						json={[
							{
								rows: [
									{key: `\t"plan":`, value: `"${utils.subscriptionTypes["standard"].name}"`, comma: true},
									{key: `\t"monthlyRequestLimit":`, value: utils.subscriptionTypes["standard"].rpm, comma: true},
									{key: `\t"requestsUsed":`, value: 1000, comma: true},
									{key: `\t"remainingRequests":`, value: utils.subscriptionTypes["standard"].rpm - 1000, comma: true},
									{key: `\t"resetDate":`, value: `"2019-03-01"`, comma: false},
								]
							},
						]}
						isStatus
					/>*/}
					<ParamsSection 
						path="conferences"
						overview="Returns a list of conferences and divisions within the specified league.  Use this endpoint to obtain conference and division names to be used as parameters for other requests."
						arguments={[
							{header: "league", required: true, body: `A valid league code`},
						]}
						exampleBody={`${apiUrl}conferences?league=NFL`}
						json={[
							{
								rows: [
									{key: `\t\t"league":`, value: `"NFL"`, comma: true},
									{key: `\t\t"conference":`, value: `"AFC"`, comma: true},
									{key: `\t\t"division":`, value: `"East"`, comma: false},
								]
							},
							{
								rows: [
									{key: `\t\t"league":`, value: `"NFL"`, comma: true},
									{key: `\t\t"conference":`, value: `"AFC"`, comma: true},
									{key: `\t\t"division":`, value: `"North"`, comma: false},
								]
							},
						]}
					/>
					<ParamsSection
						path="teams"
						overview="Returns a list of teams within a specified league, conference, or division."
						arguments={[
							{header: "league", required: true, body: `A valid league code`},
							{header: "conference", required: false, body: "A conference within the specified league"},
							{header: "division", required: false, body: "A division within the specified conference"}
						]} 
						exampleBody={`${apiUrl}teams?league=NFL&conference=AFC`}
						json={[
							{
								rows: [
									{key: `\t\t"team":`, value: `"Buffalo Bills"`, comma: true},
									{key: `\t\t"location":`, value: `"Buffalo"`, comma: true},
									{key: `\t\t"mascot":`, value: `"Bills"`, comma: true},
									{key: `\t\t"abbreviation":`, value: `"BUF"`, comma: true},
									{key: `\t\t"league":`, value: `"NFL"`, comma: true},
									{key: `\t\t"conference":`, value: `"AFC"`, comma: true},
									{key: `\t\t"division":`, value: `"East"`, comma: false},
								],
								keys: [`\t\t"team":\t`, `\t\t"location":\t`, `\t\t"mascot":\t`, `\t\t"abbreviation":\t`, `\t\t"league":\t`, `\t\t"conference":\t`, `\t\t"division":\t`],
								values: [`"Buffalo Bills"`, `"Buffalo"`, `"Bills"`, `"BUF"`, `"NFL"`, `"AFC"`, `"East"`]
							},
							{
								rows: [
									{key: `\t\t"team":`, value: `"Miami Dolphins"`, comma: true},
									{key: `\t\t"location":`, value: `"Miami"`, comma: true},
									{key: `\t\t"mascot":`, value: `"Dolphins"`, comma: true},
									{key: `\t\t"abbreviation":`, value: `"MIA"`, comma: true},
									{key: `\t\t"league":`, value: `"NFL"`, comma: true},
									{key: `\t\t"conference":`, value: `"AFC"`, comma: true},
									{key: `\t\t"division":`, value: `"East"`, comma: false},
								],
								keys: [`\t\t"team":\t`, `\t\t"location":\t`, `\t\t"mascot":\t`, `\t\t"abbreviation":\t`, `\t\t"league":\t`, `\t\t"conference":\t`, `\t\t"division":\t`],
								values: [`"Miami Dolphins"`, `"Miami"`, `"Dolphins"`, `"MIA"`, `"NFL"`, `"AFC"`, `"East"`]
							},
						]}
					/>
					<ParamsSection
						path="games"
						overview="Returns a list of games."
						arguments={[
							{header: "league", required: false, body: `A valid league code`},
							{header: "conference", required: false, body: "A conference within the specified league"},
							{header: "division", required: false, body: "A division within the specified conference"},
							{header: "date", required: false, body: "One or two comma-separated YYYY-MM-DD- or ISO-formatted dates (Eastern Time Zone)"},
							{header: "team", required: false, body: "A team participating in one or more games"},
							{header: "status", required: false, body: `A valid status of one of the following: "scheduled", "in progress", "final", "canceled", "delayed", or "suspended"`},
							{header: "odds", required: false, body: `A comma-separated filter to select games with one or more of the following odds types: "spread", "moneyline", "total", or "any"`},
							{header: "skip", required: false, body: `The number of game results to skip, which is required to access results beyond the first 100`},
						]}
						exampleBody={`${apiUrl}teams?league=NFL&conference=AFC`}
						json={[
							{
								rows: [
									{key: `\t\t"gameId":`, value: `"tv7mnr2-g7c4fe5-p95kxge-ptyhr72"`, comma: true},
									{key: `\t\t"details":`, value: `{`, comma: false},
									{key: `\t\t\t"league":`, value: `"NFL"`, comma: true},
									{key: `\t\t\t"season":`, value: 2018, comma: true},
									{key: `\t\t\t"seasonType":`, value: `"regular"`, comma: true},
									{key: `\t\t\t"conferenceGame":`, value: true, comma: true},
									{key: `\t\t\t"divisionGame":`, value: true, comma: false},
									{key: "\t\t", value: `}`, comma: true},
									{key: `\t\t"schedule":`, value: `{`, comma: false},
									{key: `\t\t\t"date":`, value: `"2018-12-30T18:00:00.000Z"`, comma: true},
									{key: `\t\t\t"tbaTime":`, value: false, comma: false},
									{key: "\t\t", value: `}`, comma: true},
									{key: `\t\t"teams":`, value: `{`, comma: false},
									{key: `\t\t\t"away":`, value: `{`, comma: false},
									{key: `\t\t\t\t"team":`, value: `"Miami Dolphins"`, comma: true},
									{key: `\t\t\t\t"location":`, value: `"Miami"`, comma: true},
									{key: `\t\t\t\t"mascot":`, value: `"Dolphins"`, comma: true},
									{key: `\t\t\t\t"abbreviation":`, value: `"MIA"`, comma: true},
									{key: `\t\t\t\t"conference":`, value: `"AFC"`, comma: true},
									{key: `\t\t\t\t"division":`, value: `"East"`, comma: false},
									{key: "\t\t\t", value: `}`, comma: true},
									{key: `\t\t\t"home":`, value: `{`, comma: false},
									{key: `\t\t\t\t"team":`, value: `"Buffalo Bills"`, comma: true},
									{key: `\t\t\t\t"location":`, value: `"Buffalo"`, comma: true},
									{key: `\t\t\t\t"mascot":`, value: `"Bills"`, comma: true},
									{key: `\t\t\t\t"abbreviation":`, value: `"BUF"`, comma: true},
									{key: `\t\t\t\t"conference":`, value: `"AFC"`, comma: true},
									{key: `\t\t\t\t"division":`, value: `"East"`, comma: false},
									{key: "\t\t\t", value: `}`, comma: false},
									{key: "\t\t", value: `}`, comma: true},
									{key: `\t\t"venue":`, value: `{`, comma: false},
									{key: `\t\t\t"name":`, value: `"New Era Field"`, comma: true},
									{key: `\t\t\t"city":`, value: `"Orchard Park"`, comma: true},
									{key: `\t\t\t"state":`, value: `"NY"`, comma: true},
									{key: `\t\t\t"neutralSite":`, value: false, comma: false},
									{key: "\t\t", value: `}`, comma: true},
									{key: `\t\t"scoreboard":`, value: `{`, comma: false},
									{key: `\t\t\t"score":`, value: `{`, comma: false},
									{key: `\t\t\t\t"away":`, value: 17, comma: true},
									{key: `\t\t\t\t"home":`, value: 42, comma: true},
									{key: `\t\t\t\t"awayPeriods":`, value: [ 0, 14, 3, 0 ], comma: true},
									{key: `\t\t\t\t"homePeriods":`, value: [ 14, 0, 14, 14 ], comma: false},
									{key: "\t\t\t", value: `}`, comma: true},
									{key: `\t\t\t"currentPeriod":`, value: `4`, comma: true},
									{key: `\t\t\t"periodTimeRemaining":`, value: `"0:00"`, comma: false},
									{key: "\t\t", value: `}`, comma: true},
									{key: `\t\t"odds":`, value: `{`, comma: false},
									{key: `\t\t\t"spread":`, value: `{`, comma: false},
									{key: `\t\t\t\t"open":`, value: `{`, comma: false},
									{key: `\t\t\t\t\t"away":`, value: 4, comma: true},
									{key: `\t\t\t\t\t"home":`, value: -4, comma: true},
									{key: `\t\t\t\t\t"awayOdds":`, value: -110, comma: true},
									{key: `\t\t\t\t\t"homeOdds":`, value: -110, comma: false},
									{key: "\t\t\t\t", value: `}`, comma: true},
									{key: `\t\t\t\t"current":`, value: `{`, comma: false},
									{key: `\t\t\t\t\t"away":`, value: 3.5, comma: true},
									{key: `\t\t\t\t\t"home":`, value: -3.5, comma: true},
									{key: `\t\t\t\t\t"awayOdds":`, value: -115, comma: true},
									{key: `\t\t\t\t\t"homeOdds":`, value: -105, comma: false},
									{key: "\t\t\t\t", value: `}`, comma: false},
									{key: "\t\t\t", value: `}`, comma: true},
									{key: `\t\t\t"moneyline":`, value: `{`, comma: false},
									{key: `\t\t\t\t"open":`, value: `{`, comma: false},
									{key: `\t\t\t\t\t"awayOdds":`, value: 130, comma: true},
									{key: `\t\t\t\t\t"homeOdds":`, value: -120, comma: false},
									{key: "\t\t\t\t", value: `}`, comma: true},
									{key: `\t\t\t\t"current":`, value: `{`, comma: false},
									{key: `\t\t\t\t\t"awayOdds":`, value: 130, comma: true},
									{key: `\t\t\t\t\t"homeOdds":`, value: -120, comma: false},
									{key: "\t\t\t\t", value: `}`, comma: false},
									{key: "\t\t\t", value: `}`, comma: true},
									{key: `\t\t\t"total":`, value: `{`, comma: false},
									{key: `\t\t\t\t"open":`, value: `{`, comma: false},
									{key: `\t\t\t\t\t"total":`, value: 59, comma: true},
									{key: `\t\t\t\t\t"overOdds":`, value: -110, comma: true},
									{key: `\t\t\t\t\t"underOdds":`, value: -110, comma: false},
									{key: "\t\t\t\t", value: `}`, comma: true},
									{key: `\t\t\t\t"current":`, value: `{`, comma: false},
									{key: `\t\t\t\t\t"total":`, value: 58.5, comma: true},
									{key: `\t\t\t\t\t"overOdds":`, value: -110, comma: true},
									{key: `\t\t\t\t\t"underOdds":`, value: -110, comma: false},
									{key: "\t\t\t\t", value: `}`, comma: false},
									{key: "\t\t\t", value: `}`, comma: true},
									{key: `\t\t\t"lastUpdated":`, value: `"2018-12-26T21:22:30.169Z"`, comma: false},
									{key: "\t\t", value: `}`, comma: true},
									{key: `\t\t"status":`, value: `"scheduled"`, comma: true},
									{key: `\t\t"lastUpdated":`, value: `"2018-12-26T21:22:30.169Z"`, comma: false},
								]
							},
						]}
					/>
					<ParamsSection
						path="gameById"
						overview="Returns a game."
						arguments={[
							{header: "gameId", required: true, body: `A unique game identifier`},
						]}
						exampleBody={`${apiUrl}gameById?gameId=tv7mnr2-g7c4fe5-p95kxge-ptyhr72`}
						noJSON="See /games example above."
					/>
					<ParamsSection
						path="odds"
						overview="Returns the odds history for a game by type."
						restricted
						arguments={[
							{header: "gameId", required: true, body: `A unique game identifier`},
							{header: "type", required: false, body: `An odds type of one of the following: "spread", "moneyline", "total", or "any"`},
						]}
						exampleBody={`${apiUrl}teams?league=NFL&conference=AFC`}
						json={[
							{
								rows: [
									{key: `\t\t"summary":`, value: `"Portland Trail Blazers @ Charlotte Hornets"`, comma: true},
									{key: `\t\t"gameId":`, value: `"ytpv0mf-6p0mae9-pn5zzcf-zjqxjd6"`, comma: true},
									{key: `\t\t"gameDate":`, value: `"2019-03-03 13:00:00.000"`, comma: true},
									{key: `\t\t"type":`, value: `"spread"`, comma: true},
									{key: `\t\t"isOpen":`, value: true, comma: true},
									{key: `\t\t"away":`, value: 3, comma: true},
									{key: `\t\t"home":`, value: -3, comma: true},
									{key: `\t\t"awayOdds":`, value: -110, comma: true},
									{key: `\t\t"homeOdds":`, value: -110, comma: true},
									{key: `\t\t"date":`, value: `"2019-03-02 20:08:17.628"`, comma: false},
									{key: "\t", value: `}`, comma: true},
									
									{key: "", value: `{`, comma: false},
									{key: `\t\t"summary":`, value: `"Portland Trail Blazers @ Charlotte Hornets"`, comma: true},
									{key: `\t\t"gameId":`, value: `"ytpv0mf-6p0mae9-pn5zzcf-zjqxjd6"`, comma: true},
									{key: `\t\t"gameDate":`, value: `"2019-03-03 13:00:00.000"`, comma: true},
									{key: `\t\t"type":`, value: `"moneyline"`, comma: true},
									{key: `\t\t"isOpen":`, value: true, comma: true},
									{key: `\t\t"awayOdds":`, value: 125, comma: true},
									{key: `\t\t"homeOdds":`, value: -145, comma: true},
									{key: `\t\t"date":`, value: `"2019-03-02 20:08:17.628"`, comma: false},
									{key: "\t", value: `}`, comma: true},

									{key: "", value: `{`, comma: false},
									{key: `\t\t"summary":`, value: `"Portland Trail Blazers @ Charlotte Hornets"`, comma: true},
									{key: `\t\t"gameId":`, value: `"ytpv0mf-6p0mae9-pn5zzcf-zjqxjd6"`, comma: true},
									{key: `\t\t"gameDate":`, value: `"2019-03-03 13:00:00.000"`, comma: true},
									{key: `\t\t"type":`, value: `"total"`, comma: true},
									{key: `\t\t"isOpen":`, value: true, comma: true},
									{key: `\t\t"total":`, value: 229, comma: true},
									{key: `\t\t"overOdds":`, value: -110, comma: true},
									{key: `\t\t"underOdds":`, value: -110, comma: true},
									{key: `\t\t"date":`, value: `"2019-03-02 20:08:17.628"`, comma: false},
								]
							},
						]}
					/>
					{!utils.noStandings && <ParamsSection
						path="standings"
						overview="Returns a summary of team records (e.g., overall, home, conference, etc.) within a specified league, conference, or division for a given year."
						arguments={[
							{header: "league", required: true, body: `A valid league code`},
							{header: "season", required: true, body: `A year in which a league season begins`},
							{header: "conference", required: false, body: "A conference within the specified league"},
							{header: "division", required: false, body: "A division within the specified conference"},
						]}
						exampleBody={`${apiUrl}standings?league=NFL&conference=AFC`}
						json={[
							{
								rows: [
									{key: `\t\t"team":`, value: `"New York Jets"`, comma: true},
									{key: `\t\t"league":`, value: `"NFL"`, comma: true},
									{key: `\t\t"conference":`, value: "AFC", comma: true},
									{key: `\t\t"division":`, value: `"East"`, comma: true},
									{key: `\t\t"record":`, value: `{`, comma: false},
									{key: `\t\t\t"overall":`, value: `{`, comma: false},
									{key: `\t\t\t\t"wins":`, value: 4, comma: true},
									{key: `\t\t\t\t"losses":`, value: 11, comma: true},
									{key: `\t\t\t\t"ties":`, value: 0, comma: true},
									{key: "\t\t\t", value: `}`, comma: true, noSpace: true},
									{key: `\t\t\t"home":`, value: `{`, comma: false},
									{key: `\t\t\t\t"wins":`, value: 2, comma: true},
									{key: `\t\t\t\t"losses":`, value: 6, comma: true},
									{key: `\t\t\t\t"ties":`, value: 0, comma: true},
									{key: "\t\t\t", value: `}`, comma: true, noSpace: true},
									{key: `\t\t\t"away":`, value: `{`, comma: false},
									{key: `\t\t\t\t"wins":`, value: 2, comma: true},
									{key: `\t\t\t\t"losses":`, value: 5, comma: true},
									{key: `\t\t\t\t"ties":`, value: 0, comma: true},
									{key: "\t\t\t", value: `}`, comma: true, noSpace: true},
									{key: `\t\t\t"conference":`, value: `{`, comma: false},
									{key: `\t\t\t\t"wins":`, value: 3, comma: true},
									{key: `\t\t\t\t"losses":`, value: 8, comma: true},
									{key: `\t\t\t\t"ties":`, value: 0, comma: true},
									{key: "\t\t\t", value: `}`, comma: true, noSpace: true},
									{key: `\t\t\t"division":`, value: `{`, comma: false},
									{key: `\t\t\t\t"wins":`, value: 1, comma: true},
									{key: `\t\t\t\t"losses":`, value: 4, comma: true},
									{key: `\t\t\t\t"ties":`, value: 0, comma: true},
									{key: "\t\t\t", value: `}`, comma: false, noSpace: true},
									{key: "\t\t", value: `}`, comma: false, noSpace: true},
								]
							},
						]}	
					/>}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
  user: state.user,
})

const DocsBody = connect(mapStateToProps)(_DocsBody);


const JSONRow = props => {
	const type = typeof props.text;
	return (<div>
		<span className="json-key">{props.title}</span>
		{props.text !== "}" && <span className="json-space" />}
		{type !== "object" && <span className={`json-${type}`}>{props.text + ""}
			<span className="json-comma">{props.comma ? " ,": ""}</span>
		</span>}
		{type === "object" && <span>
			
			<span> [</span>
			{props.text.map((per, num) => {
				return <span className="json-array" key={num}>
					<span className={`json-number ${num == 3 ? "last": ""}`}>{per}</span>
					{num != 3 && <span> ,</span>}
				</span>
			})}
			<span>]</span>
			<span className="json-comma">{props.comma ? " ,": ""}</span>
		</span>}
	</div>)
}

const ParamsSection = props => {
	return (<div className="api-section">
		<div id={`${props.path}`} className="anchor"/>
		<div className="api-section-path">{"/" + props.path}</div>
		<div className="api-section-overview">
			{props.overview}
			{props.restricted && <p className="api-section-restricted">***Only available to Standard and Premium users</p>}
		</div>
		{props.arguments.map((arg, i) => <ParamsArgument header={arg.header} required={arg.required} type={arg.type} body={arg.body} key={i}/>)}
		<ParamsExample body={props.exampleBody}>
			{props.json && <JSONSection json={props.json} isStatus={props.isStatus} />}
			{props.noJSON}
		</ParamsExample>
	</div>)
}

const JSONSection = props => {

	return (<ResponseBody>
		{props.json && !props.isStatus && <div>[</div>}
		{props.json && <div>	
			{props.json.map((item, i) => {
				return (<div key={i}>
					{props.isStatus ? "{": "\t{"}
					{item.rows.map((json, x) => {
							return <JSONRow 
							title={json.key}
							text={json.value} 
							comma={json.comma}
							noSpace={json.noSpace} 
							key={x}/>
						})
					}
					{props.isStatus ? "}": "\t} ,"}
				</div>)
			})}
			{!props.isStatus && <div>{"\n\t[ etc...... ]\n\n"}</div>}
		</div>}
		{props.json && !props.isStatus && <div>]</div>}
	</ResponseBody>)
}

const ParamsArgument = props => {
	return (<div className="api-arg">
		<div className="api-arg-header">
			<div>
				<div>{props.header}</div>
				<div className="api-arg-type">{props.type}</div>
				{props.required && <div className="api-arg-required">REQUIRED</div>}
			</div>
		</div>
		<div className="api-arg-body"><em>{props.type && `${props.type}: `}</em>{props.body}</div>
	</div>)
}

const ParamsExample = props => {
	return (<div className="api-example">
		<div className="api-example-header">Example</div>
		<div className="api-example-body">
			<div className="api-example-inner-header">REQUEST:</div>
			<div className="api-example-inner-body">{props.body}</div>
			<div className="api-example-spacer" />
			<div className="api-example-inner-header">RESPONSE:</div>
			<div className="api-example-inner-body">{props.children}</div>
		</div>
	</div>)
}



const ResponseBody = props => {
	return (
		<div id={props.anchor} className="api-response-body">
			{props.children}
		</div>
	)
}





