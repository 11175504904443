import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout, setSubscriptionType, setModalType } from '../../actions';
import './Dashboard.css';
import axios from 'axios';
import _ from 'lodash';
import utils from '../../utils';




class Dashboard extends Component {


	state = {
		showApiKey: false
	}

	componentWillMount() {
		if (utils.rapidOnly) {
			this.props.history.push("/")
		}
	}

	logout = async e => {
		e.preventDefault();
	    axios.post('/logout').then(response => {
			this.props.logout();
	    	this.props.history.push("/")
	    }).catch(err => this.props.history.push("/"));
  	}


  	render() {

	  	const apiKeyButton = this.state.showApiKey ? "Hide API Key": "Show API Key";
	    const last4 = this.props.user.card ? `************${this.props.user.card.last4}`: "No card on file"
		const cardExpiry = this.props.user.card ? `Expires: ${this.props.user.card.month}/${this.props.user.card.year}`: "";
		const subSecondary = (() => {
			const date = new Date((this.props.user.subPeriodEnd || 0) * 1000);
			const currentSub = utils.subscriptionTypes[this.props.user.subscriptionType || "basic"].index;
			const stripeSub = utils.subscriptionTypes[this.props.user.stripeSubscription || "premium"].index;
			const convertsNextPeriod = currentSub > stripeSub;
			const periodEnd = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
			switch(true) {
				case !this.props.user.apiKeyActive: return "";
				case this.props.user.subWillExpire: return `Expires ${periodEnd}`;
				case convertsNextPeriod: return `Converts to ${utils.subscriptionTypes[this.props.user.stripeSubscription].name} on ${periodEnd}`
				default: return "Active"
			}
		})();


	    return (
	    	<div className="dashboard-outer">
				{this.props.user.loginChecked && <div>
					{this.props.user.loggedIn ? <div className="dashboard">
						<h2>Dashboard</h2>
						
						<div>
							<div className="dashboard-item top">
								<div className="dashboard-item-header">Your Profile</div>
								<i className="icon user circle"></i>
								<div className="dashboard-item-body">{this.props.user.email}</div>
								{this.state.showApiKey && <div className="dashboard-item-body api">API Key: <span>{this.props.user.apiKey}</span></div>}
								<div className="dashboard-buttons">
									<button onClick={() => this.setState({showApiKey: !this.state.showApiKey})}>{apiKeyButton}</button>
									<button onClick={() => this.props.setModalType("change_password")}>Change Password</button>	
								</div>
								<div className="logout" onClick={e => this.logout(e)}>Logout</div>
								{/*<div className="dashboard-item-body secondary">jhogg11@gmail.com</div>*/}
								{/*<div className="dashboard-menu">
									<div className="dashboard-menu-item">Show API Key</div>
									<div className="dashboard-menu-item">Change Pass</div>
									<div className="dashboard-menu-item">Logout</div>
								</div>*/}
							</div>
							<div className="dashboard-inner">
								<div className="dashboard-item" onClick={() => this.props.setModalType("update_plan")}>
									<div className="dashboard-item-header">Subscription</div>
									<i className="icon checkered flag"></i>
									<div className="dashboard-item-body subscription-type">{this.props.user.apiKeyActive && this.props.user.subscriptionType ? _.startCase(this.props.user.subscriptionType): "Inactive"}</div>
									<div className="dashboard-item-body secondary subscription-status">{subSecondary}</div>
									{/*<button onClick={() => {}}>Update</button>*/}
								</div>
								<div className="dashboard-item" onClick={() => this.props.setModalType("update_card")}>
									<div className="dashboard-item-header">Card Info</div>
										<i className="icon credit card"></i>
										<div className="dashboard-item-body">{last4}</div>
										<div className="dashboard-item-body secondary">{cardExpiry}</div>
										{/*<button onClick={() => {}}>Update</button>*/}
								</div>
							</div>
						</div>
						<div className="dashboard-help" onClick={() => this.props.setModalType("contact")}>Need help?</div>
					</div>: <LoggedOutDashboard history={this.props.history} />}
				</div>}
				
	      	</div>
	    )
  	}
}


const LoggedOutDashboard = (props) => {
	return (
		<div className="logged-out-dashboard">
			<div>You must be logged in to view this page.</div>
			<div>Need an account?</div>
			<button onClick={() => props.history.push("/pricing")}>View Pricing</button>
		</div>
	)
}



const mapStateToProps = state => ({
	user: state.user,
  	subscriptionType: state.subscriptionType
})

const mapActionsToProps = {
	logout: logout,
	setSubscriptionType: setSubscriptionType,
	setModalType: setModalType
}	







export default connect(mapStateToProps, mapActionsToProps)(Dashboard);




