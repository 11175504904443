import React, { Component } from 'react';
import { connect } from 'react-redux';
import utils from '../../utils';
import './FAQ.css';
import { setModalType } from '../../actions';


const mapActionsToProps = {
  setModalType: setModalType
}

const ClearBlock = props => <div style={{height: props.height, clear: "both"}}></div>

export default connect(() => ({}), mapActionsToProps)(props => {

  return (
    <div className="faq-holder" >
        <GuideSectionHeader title="Frequently Asked Questions" />
        <GuideSection 
            header="What data does Sportspage offer?"
            content={<div>We offer the following data for the NFL, NBA, MLB, NHL, college football, and college basketball:
                <br/>
                <ul>
                    <li>Team details, including league, conference, division, mascot, and abbreviation</li>
                    <li>League schedules, including venues, season and game types, and betting odds (where available)</li>
                    <li>Real-time score updates for in progress games</li>
                    {!utils.noStandings && <li>Team standings data, including team records by game type (e.g., overall, home, conference, etc.)</li>}
                </ul>
                </div>
            }
        />
        {/*<GuideSection 
            header="How often is the data updated?"
            content={
                <div>
                    <div>
                        Scores for in-progress games are updated on a "near-live" basis (generally, within about one minute).  
                        Betting odds for future games are updated roughly every 15 minutes.
                    </div>
                </div>
            }
        />*/}
        <GuideSection 
            header="What type of betting odds are available?"
            content={
                <div>
                    <div>We provide full-game spreads, moneylines, and totals.  Odds may not be available for some games but are included for most games.</div>
                    <br/>
                    <div>For all games with odds, the opening and current odds are included.  Full odds histories, including all line and odds movements, are available for Standard and Premium users.</div>
                </div>
            }
        />
        <GuideSection 
            header="Is historical data available?"
            content={
                <div>
                    <div>
                    Seasons beginning in calendar year 2018 are available through the API, however, historical games and results are separately available back to 2009.  Send us a <a onClick={() => props.setModalType("contact")} >message</a> to let us know your needs.
                    </div>
                </div>
            }
        />
        <GuideSection 
            header="When will I be billed?"
            content={
                <div>
                    <div>
                    You will be charged upon signing up and on the same calender day (or by the end, if applicable) of subsequent months as 
                    long as your subscription is active.  Subscriptions can be canceled at any time in your Dashboard.
                    </div>
                </div>
            }
        />
        {/*<GuideSection 
            header="Can I get a test API key?"
            content={
                <div>
                    <div>
                    Test API keys are available and can be used for up to 100 API requests.  Certain data elements, such as detailed team and venue information, are only available with full subscriptions.
                    <br/><br/>
                    To get your test key, send us an <a href="#">email</a>.
                    </div>
                </div>
            }
        />*/}
        {/*<div className="divider"/>*/}
        
    </div>
  )

    
})

const GuideSectionHeader = (props) => <div className="faq-group-header">{props.title}</div>


class GuideSection extends Component {

    render() {

        return (
            <div className="faq-section">
                <div className="faq-section-left"></div>
                <div className="faq-section-header" onClick={this.onClick}>
                    <ClearBlock height={20} />
                    <div className="faq-section-title">{this.props.header}</div>
                </div>
                <div className="faq-section-body">
                    {this.props.content}
                </div>
            </div>
        )
    }

    
}



