
export default function userReducer(state = { loginChecked: false }, {type, payload}) {

	switch(type) {
		case 'SET_USER': return payload;
		case 'LOGOUT': return {
			loggedIn: false,
			loginChecked: true,
		};
		default: return state;
	}
}