const Joi = require('joi-browser');

const schema = Joi.object().keys({
	name: Joi.string()
		.regex(/^[a-z\d\-_',\s]+$/i)
		.min(3)
		.max(50)
		.required()
		.label("You must provide your name to sign up"),

	email: Joi.string()
		.email({ minDomainAtoms: 2 })
		.required()
		.label("A valid email address is required"),

    password: Joi.string()
    	.regex(/^[0-9a-zA-Z!@#$%^&*?]{8,30}$/)
    	.required()
    	.label("Password must be between 8 and 30 characters and contain only letters, numbers, or !@#$%^&*?"),

    confirmPassword: Joi.any()
    	.equal(Joi.ref('password'))
    	.required()
    	.label("Passwords must match"),

    termsChecked: Joi.boolean()
    	.invalid(false)
    	.required()
    	.label("You must agree to the Terms and Conditions to continue")
});


export default schema;