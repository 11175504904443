import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {CardElement, injectStripe } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { setUser, setSubscriptionType, setModalType } from '../../actions';
import './FormModal.css';
import axios from 'axios';
import Joi from 'joi-browser';
import joiSignup from './joi-signup';
import utils from '../../utils';


const useConfirmation = false;


class FormModal extends Component {

  state = {
    pending: false,
    showSecondary: false,
    userAuth: {
      name: undefined,
      email: undefined,
      password: undefined,
      confirmPassword: undefined
    },
    confirmDetails: {
      newPlan: undefined,
      confirmType: "cancel",//undefined
      token: undefined
    }
  }

  showSecondaryModal = (e, success, secondary, text) => {
    e.preventDefault();
    this.setState({showSecondary: true, success: success, secondaryAction: secondary, secondaryText: text})
  }

  signup = async e => {

    const name = document.getElementById("user-name").value;
    const result = Joi.validate({
      name: name,
      email: document.getElementById("user-email").value,
      password: document.getElementById("password").value,
      confirmPassword: document.getElementById("confirm-password").value,
      termsChecked: document.getElementById("signup-terms").checked
    }, joiSignup);

    const joiErr = !!result.error && result.error.details[0];

    if (!!joiErr) {
      e.preventDefault();
      const elems = document.getElementsByClassName("StripeElement")
      for (let elem of elems) {
        elem.className = elem.className.replace("--invalid", "");
      }
      const focusElem = document.getElementById((() => {
        switch(joiErr.context.key) {
          case "name": return "user-name";
          case "email": return "user-email";
          case "password": return "password";
          case "confirmPassword": return "confirm-password";
          case "termsChecked": return "signup-terms";
          default: return ""; 
        }
      })());
      focusElem.className += " --invalid";
      focusElem.focus();
      document.getElementById("form-error").innerHTML = joiErr.context.label;
      return
    }

    this.setPending(e);
    
    let stripe = await this.props.stripe.createToken({name: name});
    const token = stripe.token

    if (!token || !token.card) {
      document.getElementById("form-error").innerHTML = "There was a problem with your card details";
      this.setState({pending: false})
      return
    }

    axios.post('/signup', {
      token: token,
      type: this.props.subscriptionType,
      name: document.getElementById("user-name").value,
      email: document.getElementById("user-email").value,
      password: document.getElementById("password").value,
      confirmPassword: document.getElementById("confirm-password").value,
    }).then(response => this.handleResponse(response, true))
      .catch(err => this.handleHTTPError(err));
  }


  login = async e => {
    this.setPending(e);

    axios.post('/login', {
      email: document.getElementById("user-email").value,
      password: document.getElementById("current-password").value,
    }).then(response => this.handleResponse(response, true))
      .catch(err => this.handleHTTPError(err));
  }

  // FINISH THIS TO HAVE CUSTOMER CONFIRM BEFORE CHANGING SUBSCRIPTION

  goToConfirmation = async (e, details, auth) => {

    e.preventDefault();
    
    this.setState({pending: false, confirmDetails: details, userAuth: auth}, () => {

      const date = new Date((this.props.user.subPeriodEnd || 0) * 1000);
      const shortDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      if (this.props.subscriptionType === this.props.user.stripeSubscription && !this.props.user.subWillExpire && details.confirmType !== "cancel") {
        const agreesToCurrent = this.props.subscriptionType === this.props.user.subscriptionType;      
        const err = `Selected plan must differ from ` + (agreesToCurrent ? "your current plan": `the plan that is scheduled to renew at ${shortDate}`);
        document.getElementById("form-error").innerHTML = err;
      } else if (this.props.user.subWillExpire && details.confirmType === "cancel") {
        document.getElementById("form-error").innerHTML = `Plan is already set to expire at ${shortDate}`;
      } else {
        document.getElementById("form-error").innerHTML = "";
        this.props.setModalType("confirm");
      }
    })
  }

  updateSubscription = async (e, cancel) => {
    const route = cancel ? "/cancelSubscription": "/updateSubscription";
    this.setPending(e);

    axios.post(route, {
      password: document.getElementById("current-password").value,//this.state.userAuth.password, //
      newPlan: this.props.subscriptionType,
    }).then(response => this.handleResponse(response, false))
      .catch(err => this.handleHTTPError(err));
  }

  updateCard = async e => {
    this.setPending(e);
    const name = document.getElementById("user-name").value;
    let {token} = await this.props.stripe.createToken({name: name});

    axios.post('/updateCard', {
      token: token,
      name: name,
      password: document.getElementById("current-password").value,
    }).then(response => this.handleResponse(response, false))
      .catch(err => this.handleHTTPError(err));
  }

  sendPassword = async e => {
    this.setPending(e);

    axios.post('/sendPassword', {
      email: document.getElementById("user-email").value,
    }).then(response => this.handleResponse(response))
    .catch(err => this.handleHTTPError(err));
  }

  changePassword = async e => {
    this.setPending(e);

    axios.post('/changePassword', {
      password: document.getElementById("current-password").value,
      newPassword: document.getElementById("password").value,
      confirmNewPassword: document.getElementById("confirm-password").value,
    }).then(response => this.handleResponse(response))
      .catch(err => this.handleHTTPError(err));
  }

  contactSupport = async e => {
    this.setPending(e);

    axios.post('/contactSupport', {
      name: document.getElementById("user-name").value,
      email: document.getElementById("user-email").value,
      message: document.getElementById("contact-message").value,
    }).then(response => this.handleResponse(response, false, true))
      .catch(err => this.handleHTTPError(err, true));
  }

  goToPricing = () => {
    if (this.props.location.pathname === "/pricing") {
      this.props.setModalType('signup');
    } else {
      this.props.setModalType();
      this.props.history.push("/pricing")
    }
  }

  setNewModal = (e, type) => {
    e.preventDefault();
    this.props.setModalType(type);
  }

  setPending = (e) => {
    e.preventDefault();
    this.setState({pending: true})
  }

  handleResponse = (response, setUser, maintainData) => {
    if (response.data.success) {

      this.setState({pending: false});  
      
      if (setUser) {
        this.props.setUser(response.data);
        this.props.setModalType();
        this.props.history.push("/dashboard");
      } else {
        if (!maintainData) {
          this.props.getUserData();
        }
        this.setState({showSecondary: true})
      }
    } else {
      document.getElementById("form-error").innerHTML = response.data.error;
      this.setState({pending: false});
    }
  }

  handleHTTPError = (error, requireEmail) => {

    let errMessage;

    if (requireEmail) {
      errMessage = "Oops, there was a problem.  Please contact us at sportspagefeeds@gmail.com.";
    } else {
      errMessage = "There was a problem.  If the issue persists, contact support.";
    }

    try {
      this.setState({pending: false});
      document.getElementById("form-error").innerHTML = error || errMessage;
    } catch(err) {
      document.getElementById("form-error").innerHTML = errMessage;
    }
  }

  goToTerms = (e) => {
    e.preventDefault();
    window.open('/terms', '_blank');
  }

  render() {

    // console.log(this.props.getUserData("hey"))

    const type = this.props.modalType;
    const confirmType = this.state.confirmDetails.confirmType;
    const attributes = (() => {
      switch(this.props.modalType) {
        case "signup": return {
          header: "Subscribe",
          newPassword: true,
          nameLabel: "Full Name",
          showEmail: true,
          showCard: true,
          showPlan: true,
          buttonText: !useConfirmation ? "Submit": "Review",
          buttonAction: this.signup
        };
        case "login": return {
          header: "Login",
          showEmail: true,
          requirePasswordSecondary: true,
          buttonText: "Login",
          buttonAction: this.login,
          secondaryButton: true,
          secondaryButtonText: "New Account",
          secondaryButtonAction: this.goToPricing,
        };
        case "forgot_password": return {
          header: "Reset Password",
          showEmail: true,
          textBody: `Enter your email to receive a password reset link.`,
          buttonText: "Send Link",
          buttonAction: this.sendPassword
        };
        case "change_password": return {
          header: "Change Password",
          requirePassword: true,
          newPassword: true,
          buttonText: "Change",
          buttonAction: this.changePassword
        };
        case "update_plan": return {
          header: "Change Plan",
          showPlan: true,
          requirePasswordSecondary: true,
          textBody: `If you are upgrading your plan, you will be charged the prorated difference for the month and your access will be updated immediately.  Otherwise, your new plan will take effect at the next billing cycle.`,
          buttonText: !useConfirmation ? "Update": "Review",
          buttonAction: !useConfirmation ? 
            this.updateSubscription:
            e => this.goToConfirmation(e, {
            newPlan: false,
            confirmType: "change"
          }, {password: document.getElementById("current-password").value}),
          secondaryButton: true,
          secondaryButtonText: "Cancel Subscription",
          secondaryButtonAction: !useConfirmation ? 
            e => this.updateSubscription(e, true):
            e => this.goToConfirmation(e, {
            newPlan: false,
            confirmType: "cancel"
          }, {password: document.getElementById("current-password").value})
        };
        case "update_card": return {
          header: "Update Card Details",
          nameLabel: "Name On Card",
          showCard: true,
          requirePasswordSecondary: true,
          buttonText: "Update",
          buttonAction: this.updateCard
        };
        case "contact": return {
          header: "Contact",
          nameLabel: "Name",
          showName: true,
          showEmail: true,
          secondaryButton: true,
          buttonText: "Send Message",
          buttonAction: e => this.contactSupport(e),
          secondaryButtonText: "Close",
          secondaryButtonAction: e => this.props.setModalType()
        };
        case "api_key_sent": return {
          header: "API Key Sent",
          textBody: "Your API key has been sent to your email.",
          buttonText: "Close",
          buttonAction: () => this.props.setModalType()
        };
        case "confirm": return {
          header: confirmType === "new" ? "Confirm Subscription": "Confirm Plan Change",
          showConfirm: true,
          textBody: (() => {
            switch(this.state.confirmDetails.confirmType) {
              case "change": return "You are about to change your subscription type.  Review the details below and confirm the change.";
              case "new": return "Review the plan details below and confirm your subscription.  Your API key will be emailed and also available on your dashboard."
              case "cancel": return "You are about to cancel your plan.  If you're sure you want cancel, hit 'Confirm' below to proceed."
              default: return ""
            }
          })(),
          buttonText: "Confirm",
          buttonAction: this.updateSubscription,
          secondaryButton: true,
          secondaryButtonText: "Cancel",
          secondaryButtonAction: e => this.props.setModalType()
        };
        default: return {};

      }
    })();


    return (
      <div className="modal-holder">
        {this.state.showSecondary ?
          <SecondaryModal type={this.props.modalType} />:
        <div className="form-modal">
          <div>
            <div className="modal-header">
              {attributes.header}
              <div className="x-holder" onClick={() => this.props.setModalType()}>
                <svg id="betSlipX" viewBox="0 0 100 100" >
                  <line x1="20" y1="20" x2="80" y2="80" className="x-line" />
                  <line x1="20" y1="80" x2="80" y2="20" className="x-line" />
                </svg>
              </div> 
            </div>
            {attributes.textBody && <div className="plan-update">{attributes.textBody}</div>}
            {attributes.showConfirm && <ConfirmSection confirmDetails={this.state.confirmDetails} />}
            <form id="payment-form">
              {(attributes.showCard || attributes.showName) && <FormRow id="user-name" label={attributes.nameLabel} placeholder={type === "contact" ? "": "John Doe"} />}
              {attributes.showEmail && <FormRow type="email" id="user-email" label="Email" placeholder={type === "contact" ? "": "john.doe@example.com"} />}
              {attributes.requirePassword && <FormRow type="password" id="current-password" label="Current Password"/>}
              {attributes.newPassword && <FormRow type="password" id="password" 
                label={this.props.modalType === "change_password" ? "New Password": "Password"} 
                placeholder="Must be at least 8 characters" />
              }
              {attributes.newPassword && <FormRow type="password" id="confirm-password" label="Confirm Password" />}

              {/*<button onClick={(e) => this.submitSubscription(e, card)}>Submit</button>*/}
              {attributes.showCard && <CheckoutForm subscriptionType={this.props.subscriptionType}/>}
              {attributes.showPlan && <div className="form-row">
                <label htmlFor="card-element">Subscription Type</label>
                <select id="select-plan" value={this.props.subscriptionType || "basic"} onChange={(e) => {
                  this.props.setSubscriptionType(e.target.value)}
                }>
                  <option value="basic">{`Basic - $${utils.subscriptionTypes["basic"].price.toLocaleString()}.00`}</option>
                  <option value="standard">{`Standard - $${utils.subscriptionTypes["standard"].price.toLocaleString()}.00`}</option>
                  <option value="premium">{`Premium - $${utils.subscriptionTypes["premium"].price.toLocaleString()}.00`}</option>
                </select>
              </div>}
              {attributes.requirePasswordSecondary && <FormRow type="password" id="current-password" label={type === "login" ? "Password": "Current Password"} placeholder="Enter password"/>}
              {type === "contact" && <FormRow label="Message" isTextBox />}
              <div id="form-error"></div>
              {type === "signup" && <div className="modal-confirm-terms">
                <input type="checkbox" name="terms" id="signup-terms"></input>
                <label>I agree to the Sportspage Feeds <Link to="/terms" target="_blank" className="terms-link" onClick={this.goToTerms}>Terms and Conditions</Link></label>
              </div>}
              <button onClick={attributes.buttonAction} disabled={this.state.pending}>
                {this.state.pending ? "": attributes.buttonText}
                {this.state.pending && <div className="loader"></div>}
              </button>
              {attributes.secondaryButton && 
                <button className="secondary-button" onClick={(e) => attributes.secondaryButtonAction(e, true)}>{attributes.secondaryButtonText}</button>}
            </form>
            {type === "login" && <div className="forgot-password" onClick={() => this.props.setModalType("forgot_password")}>Forgot Password?</div>}
          </div>
        </div>}
      </div>
      
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  subscriptionType: state.subscriptionType,
  modalType: state.modalType
})

const mapActionsToProps = {
  setUser: setUser,
  setSubscriptionType: setSubscriptionType,
  setModalType: setModalType
}

const ConfirmSection = connect(mapStateToProps, mapActionsToProps)(props => {

  const confirmType = props.confirmDetails.confirmType;
  const currSub = props.user.stripeSubscription;
  const nextSub = props.subscriptionType;
  const token = props.confirmDetails.token;
  const date = new Date((props.user.subPeriodEnd || 0) * 1000);


  return (
    <div className="modal-confirm">
      {props.confirmType === "new" && <div className="modal-confirm-section">
        <div>Contact Details</div>
        <div className="modal-confirm-details small">{props.userAuth.name}</div>
        <div className="modal-confirm-details small">{props.userAuth.email}</div>
      </div>}
      {confirmType !== "cancel" && <div className="modal-confirm-section">
        <div>{confirmType === "new" ? 'Subscription': 'New Plan'}</div>
        <div className="modal-confirm-details">
          {`${utils.subscriptionTypes[nextSub].name} - $${utils.subscriptionTypes[nextSub].price}.00`}
          <span> / month</span>
        </div>
      </div>}
      {["change", "cancel"].includes(confirmType) && <div className="modal-confirm-section">
        <div>Current Plan</div>
        {props.user.subWillExpire ? <div className="modal-confirm-details small">
          {`${utils.subscriptionTypes[props.user[props.user.subWillExpire ? "subscriptionType": "stripeSubscription"]].name} plan expires on 
          ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`}
        </div>:
        <div className="modal-confirm-details">
          {`${utils.subscriptionTypes[currSub].name} - $${utils.subscriptionTypes[currSub].price}.00`}
          <span> / month</span>
        </div>}
      </div>}
      {confirmType === "new" && <div className="modal-confirm-section">
        <div>Card</div>
        <div className="modal-confirm-details small">{`************${token.card.last4}`}</div>
        <div className="modal-confirm-details small">{`Exp: ${token.card.exp_month}/${token.card.exp_year}`}</div>
      </div>}
      {confirmType === "new" ? <div className="modal-confirm-terms">
        <input type="checkbox" name="terms"></input>
        <label>I agree to the Sportspage Feeds <Link to="/terms" className="terms-link">Terms and Conditions</Link></label>
      </div>:
      <div className="modal-confirm-spacer"/>
    }
    </div>
  )
})

const FormRow = props => {
  return (
    <div className="form-row">
      <label htmlFor="card-element">{props.label}</label>
      {props.isTextBox ? <textarea id="contact-message" className="StripeElement"></textarea>:
        <input type={props.type} id={props.id} className="StripeElement" placeholder={props.placeholder}></input>
      }
    </div>
  )
}


const CheckoutForm = injectStripe(props => {

    return (
        <div className="form-row">
          <label htmlFor="card-element">Card Details</label>
          <CardElement />
        </div>
    );
})



class SecondaryModalRaw extends Component {

  state = {
    success: this.props.success
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.success !== prevProps.success) {
      document.getElementById("secondary-i").classList.add("fade");
      setTimeout(() => {
        this.setState({success: true}, () => {
          document.getElementById("secondary-i").classList.remove("fade");
        })
      }, 500)
    }
  }

  onClick = e => {
    e.preventDefault();
    this.props.setModalType();
  }


  render() {

    return (
      <div className="success-modal">
        <i id="secondary-i" className="icon check circle outline"></i>
        <div>Success!</div>
        <button className="secondary-button" onClick={e => this.onClick(e)}>Close</button>
      </div>
    )
  }
}

const SecondaryModal = connect(mapStateToProps, mapActionsToProps)(SecondaryModalRaw);




export default connect(mapStateToProps, mapActionsToProps)(injectStripe(FormModal));




