

export function setUser(user = {}) {

	return {
		type: 'SET_USER',
		payload: {
			loginChecked: true,
			loggedIn: true,
			email: user.email,
			apiKey: user.apiKey,
			apiKeyActive: user.apiKeyActive,
			subWillExpire: user.subWillExpire,
        	subPeriodEnd: user.subPeriodEnd,
			subscriptionType: user.subscriptionType,
			stripeSubscription: user.stripeSubscription,
			signupDate: user.signupDate,
			card: user.card
		}
	}
}

export function logout() {
	return {
		type: 'LOGOUT'
	}
}

export function setSubscriptionType(subscriptionType = "basic") {

	return {
		type: 'SET_SUBSCRIPTION_TYPE',
		payload: {
			subscriptionType: subscriptionType
		}
	}
}

export function setModalType(modalType = "closed") {


	const typeLookup = {
		closed: 'CLOSE_POPUP',
		signup: 'SHOW_SIGNUP',
		login: 'SHOW_LOGIN',
		forgot_password: 'FORGOT_PASSWORD',
		change_password: 'CHANGE_PASSWORD',
		update_card: 'UPDATE_CARD',
		update_plan: 'UPDATE_PLAN',
		contact: "CONTACT",
		confirm: 'CONFIRM',
		api_key_sent: 'API_KEY_SENT'
	}
	
	return {
		type: typeLookup[modalType] || 'CLOSE_POPUP'
	}
}


